<template>
    <v-row align="center">
        <v-col cols="12" justify="center" align="center">
            <iframe width="90%" height="700" src="https://jurorportal.nmcourts.gov/qualification/"
                sandbox="allow-scripts allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"
                class="iframe" title="New Mexico Juror Portal">
            </iframe>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'juryCheckin'
}
</script>